import CategoryHead from "@/components/categoryHead"
import { FC, useCallback, useEffect, useState } from "react"
import styles from "./styles/news.module.css"
import Footer from "@/components/footer"
import useAxios from "@/hooks/useAxios"
import Pagination from "@/components/pagination"
import u, { cancelAllPreviousFetchRequests } from "@/util"
import Img from "@/components/img"
import useConfBase from "@/hooks/useConfBase"

const News: FC = () => {
    const [selectedIdx, setSelectedIdx] = useState<number>(0)
    const { req } = useAxios("list/base", "post");
    const { req: req2 } = useAxios("appdown/base", "post");
    const [imgList, setImglist] = useState<any[]>([]);
    const [dte, setDte] = useState<any>({})
    const [liveUrl, setLiveUrl] = useState<any>({})
    const [suggestList, setSuggestlist] = useState<any[]>([])
    const [config] = useConfBase();
    const [imgAll, setImgAll] = useState<any>({})
    const [encryptUrl, setEncryptUrls] = useState<any[]>([])

    const fetchDetails = useCallback(async (page?: number,) => {
        cancelAllPreviousFetchRequests();
        const _val = listD.find((_: any, i: number) => i === selectedIdx)?.value
        const res = await req({
            channel: "news",
            name: _val,
            page: page || 1,
        })
        if (res) {
            if (u.isMobile()) {
                setDte(res?.data?.list)
            } else {
                setDte(res?.data?.list)
                const _img = res?.data?.list?.data.map((x: any) => `${config?.image_url}/${x.thumb[0]}.txt`);
                setEncryptUrls(_img)
                // await u.fetchInBatches(_img, setImglist);
            }
            setLiveUrl(res?.data?.cat_list[3].url);
        }
    }, [selectedIdx, req, config])

    const loadMobImg = useCallback(async () => {
        if (Array.isArray(dte?.data) && u.isMobile()) {
            cancelAllPreviousFetchRequests();
            const allImgs = dte?.data?.map((x: any) => x.thumb);
            setImgAll(allImgs)
            // let newd = {}
            // for (const [index, el] of allImgs.entries()) {
            //     const _img = el.map((x: any) => u.fetchData(`${config?.image_url}/${x}.txt`));
            //     const results = await Promise.all(_img);

            //     const decrypt = results.map((x) => {
            //         try {
            //             return u.imgDecrypt(x)
            //         } catch {
            //             return ''
            //         }
            //     })
            //     newd = { ...newd, [index]: decrypt };
            // }
            // setImgAll(newd)
        }
    }, [dte])

    useEffect(() => {
        loadMobImg()
    }, [loadMobImg])

    const fetchSuggestionList = useCallback(async () => {
        const res = await req2({})
        if (res) {
            setSuggestlist(res?.data?.list)
        }
    }, [])

    useEffect(() => {
        console.log(config)
        if (selectedIdx <= 1) {
            fetchDetails()
        }
        if (selectedIdx === 2) {
            fetchSuggestionList()
        }

    }, [fetchDetails, fetchSuggestionList, selectedIdx])

    return (
        <div>
            <CategoryHead showCategory hideAds>
                <div className="mw1100 grid gap10">
                    <div className="relative">
                        <div className={styles.min}>
                            {listD.map((x, i) => {
                                return <div key={i} className="relative" onClick={() => {
                                    cancelAllPreviousFetchRequests()
                                    if (i == 3) {
                                        return window.open(liveUrl)
                                    }else{
                                        setSelectedIdx(i);
                                    }
                                }}>
                                    <div>{x.name}</div>
                                    {i !== 3 && i === selectedIdx && <div className={`abs ${styles.ylow}`}></div>}
                                </div>
                            })}
                        </div>

                        {selectedIdx <= 1 && <div className={styles.wrapper}>
                            <div>
                                <div className={styles.ui1}>
                                    {dte?.data?.map((x: any, i: number) => {
                                        return <div key={i} className={styles.news_f_item} onClick={() => window.open(`/s/detail/news/${x.id}`)}>
                                            <div className={styles.left}>
                                                <div className={styles.left_title}>{x.title}</div>
                                                <div>发布于：{u.toFmt(x.update_time * 1000)}</div>
                                            </div>
                                            <Img encryptUrl={encryptUrl[i]} width={240} height={120} src={imgList[i]} />
                                        </div>
                                    })}
                                </div>
                                <div className={styles.ui2}>
                                    {dte?.data?.map((x: any, i: number) => {
                                        const imglen = imgAll[i] || [];
                                        return <div key={i} className={styles.news_f_item} onClick={() => window.open(`/s/detail/news/${x.id}`)}>
                                            <div>{x.description}</div>
                                            <div className={styles.ui2_img}>
                                                {imglen.length >= 3 && <div>
                                                    {imglen.map((t: any, idx: number) => {
                                                        return <Img encryptUrl={`${config?.image_url}/${t}`} key={idx} src={`${config?.image_url}/${t}`} />
                                                    })}
                                                </div>}
                                            </div>
                                            <div>发布于：{u.toFmt(x.update_time * 1000)}</div>
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className="mt10">
                                <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dte?.last_page} total={dte?.total} changeHandler={ async (e) => {
                                    await fetchDetails(e.current);
                                    setImglist([])
                                    setImgAll({})
                                }} />
                            </div>
                        </div>}


                        {
                            selectedIdx === 2 && <div>
                                <div className={styles.suggest}>
                                    {suggestList.map((x, i) => {
                                        return <div key={i} className={styles.inner} onClick={() => window.open(`/s/detail/app/${x.id}`)}>
                                            <div>
                                                <Img src={x.icon} width={54} height={54} />
                                            </div>
                                            <div className={styles.left_new}>
                                                <div>
                                                    <div className={styles.news_c_t}>{x?.name}</div>
                                                    <div className={styles.news_c_p}>{x?.tags}</div>
                                                    <div className={styles.news_c_b}>{x?.down_time}已下载</div>
                                                </div>
                                                <div className={styles.get}>
                                                    获取
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        }

                    </div>
                    <Footer />
                </div>
            </CategoryHead>
        </div>
    )
}
export default News;

const listD = [
    {
        name: "新闻",
        value: "xw"
    },
    {
        name: "福利",
        value: "fl"
    },
    {
        name: "APP推荐",
        value: ""
    },
    {
        name: "直播",
        hide: true
    },
]