import useModal, { EModalTypes, IModal } from "@/hooks/useModal";
import { FC, useCallback, useEffect, useState } from "react";
import styles from "@/components/styles/modal.module.css"
import vipModalStyles from "@/components/styles/vipModal.module.css"
import Button from "../button";
import u, { ELocalKey, EVIPtype } from "@/util";



const BuyTopicModal: FC<IModal> = () => {
    const [modalData, closeModal] = useModal();
    const [buy, setBuy] = useState<string>("");
    
    useEffect(() => {
      if(window.location.href.indexOf("login") >= 0 && localStorage.getItem(ELocalKey._BT)){
        setBuy(localStorage.getItem(ELocalKey._BT)+"")
    }
    }, [])

    const handleBuy = useCallback((price?: any) => {
        let id =window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1,window.location.pathname.length);
        if(localStorage.getItem(ELocalKey._BT)){
            id = localStorage.getItem(ELocalKey._BT)?.substring(0, localStorage.getItem(ELocalKey._BT)?.lastIndexOf('-')) ?? '6'
            localStorage.removeItem(ELocalKey._BT)
            return window.open(`/page/topic/${id}`)
        }
        
        if (!u.isLogin()) {
            localStorage.setItem(ELocalKey._BT,id + '-' + price);
            return window.location.href = "/login"
        }
        try {
            let i = price.toString();
            i = u.encrypt(i);
            u.open(`/user/chargetopic?id=`+id+`&i=`+i+`&ty=topic`)
        } catch(err) { 
            console.log(err)
        }
    }, [])

    return (
        modalData?.show && modalData?.id ===  EModalTypes.BUYTOPIC && <div className={styles.common}>
            <div className={`${styles.body} buy_topic_modal`}>
                <div onClick={() => {
                   closeModal()
                }} className={styles.close}>X</div>
                <div className={`${styles.content} ${vipModalStyles.content}`}>    
                    <div className={`${styles.content_title} mt20`}>温馨提示 </div>
                    <div className={`${styles.content_inner_title} mt10`}>
                        {!u.isVip() && buy && <>马上带您去购买主题</>}
                        {u.isVip() && buy && <>尊贵的猫咪VIP欢迎您</>}
                        {!buy && <>您还未购买主题包</>}
                    </div>
                    <div className={`${vipModalStyles.redexpire} fz14 point`} onClick={()=>handleBuy(modalData?.data.data.price)}>
                        {/* {buy && <>点击马上去主题详情</>} */}
                    </div>
                    <div className={`fl align_center justify_center mt30 ${vipModalStyles.btm}`}>
                        {/* <Link to={`/user/buy`}> */}
                            <Button cls="btn_sty4 point" event={()=>handleBuy(modalData?.data.data.price)}>
                                {buy && <>立即跳转</>}
                                {!buy && <>立即购买</>}
                            </Button>                            
                        {/* </Link> */}

                        {/* <Link to="/page/vip/57"> */}
                            <Button cls="btn_sty3 point" event={() => { closeModal() }}>确认</Button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BuyTopicModal;