import useModal, { EModalTypes, IModal } from "@/hooks/useModal";
import { FC, useEffect } from "react";
import styles from "@/components/styles/modal.module.css"
import vipModalStyles from "@/components/styles/vipModal.module.css"
import Img from "../img";
import { Link } from "react-router-dom";
import Button from "../button";
import u, { EVIPtype } from "@/util";
import useUser from "@/hooks/useUser";

const wh = { height: "83%", width: "100%" }

const VipModal: FC<IModal> = () => {
    const [modalData, closeModal] = useModal();
    const [userInfo] = useUser();

    return (
        modalData?.show && modalData?.id ===  EModalTypes.VIP && <div className={styles.common}>
            <div className={`${styles.body} vip_modal`}>
                <div className="abs" style={{...wh, height: "100%"}}>
                    <Img style={wh} src="/images/dialog/vipBlue.png" />
                </div>
                <div onClick={() => {
                    u.formUser(EVIPtype.BUY, modalData?.data?.data?.data?.data)
                }} className={styles.close}>X</div>
                <div className={`${styles.content} ${vipModalStyles.content}`}>    
                    <div className={`${styles.content_title} mt20`}>温馨提示</div>
                    <div className={`${styles.content_inner_title} mt10`}>尊贵的猫咪VIP欢迎您</div>
                    <div className={`${vipModalStyles.redexpire} ${vipModalStyles.mt110} fz16`}>
                        {modalData?.data?.data?.time ? <span>你的VIP将于{`${modalData?.data?.data?.time || ''} `}过期</span> : <br/>  } 
                    </div>
                    <div className={`${vipModalStyles.redexpire} fz14 point`}>点击进入用户中心</div>
                    <div className={`fl align_center justify_center ${vipModalStyles.btm}`}>
                        {/* <Link to={`/user/buy`}> */}
                        {
                            userInfo?.is_vip !== '2' && <>
                            <Button cls="btn_sty4 point" event={() => { u.formUser(EVIPtype.BUY, modalData?.data?.data?.data?.data) }}>立即续费</Button>                            
                            </>
                        }
                        {/* </Link> */}

                        {/* <Link to="/page/vip/57"> */}
                            <Button cls="btn_sty3 point" event={() => { u.formUser(EVIPtype.VIP, modalData?.data?.data?.data?.data) }}>确认</Button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VipModal;