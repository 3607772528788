import CategoryHead from "@/components/categoryHead"
import { FC, useCallback, useEffect, useState } from "react"
import styles from "./styles/bill.module.css"
import Footer from "@/components/footer"
import Back from "@/components/back"
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
import Empty from "@/components/empty"
import useRoleAuth from "@/hooks/useRoleAuth"

const UserBill: FC = () => {
    useRoleAuth()
    const [user] = useUser()
    const { req } = useAxios("user/bill", "post");
    const [list, setList] = useState<any[]>([])
    const fetchBillDetails = useCallback(async () => {
        if (!user?.token) return;
        const res = await req({
            token: user?.token
        });
        if (Array.isArray(res?.data)) {
            setList(res?.data)
        }
    }, [user])

    useEffect(() => {
        fetchBillDetails()
    }, [fetchBillDetails])

    return (
        <CategoryHead>
            <div className="plr mh700 mt80">
                <div className="mw1100 mb10">
                    <Back>充值记录</Back>
                    {
                        Array.isArray(list) && list.length > 0 && list.map((x, i) => {
                            return <div key={i}>
                                <div className={styles.up}>{x?.month}</div>
                            {
                                Array.isArray(x.list) && x.list.map((t: any) => {
                                    return <div className={styles.billitem}>
                                    <div>
                                        <div>{t?.order_time}</div>
                                        <div>{t?.order_id}</div>
                                    </div>
                                    <div>￥{t?.amount}</div>
                                    <div className={styles.topic}>{t?.goods_type === "topic"? `主题包购买` : `VIP购买`}</div>
                                </div>
                                })
                            }
                            </div>
                        })
                    }
                    {list.length === 0 && <Empty></Empty>}
                </div>
            </div>
            <Footer />
        </CategoryHead>
    )
}
export default UserBill