import { FC } from "react"
import Img from "./img"

interface IEmpty {
    children?: string
}

const Empty: FC<IEmpty> = ({ children }) => {
    return (
        <div className="grid align_center justify_center mb20">
            <Img  width={160} height={150} src="/images/default/nodata/no-found.png" />
            <div className="text_center">加载中...</div>
        </div>
    )
}
export default Empty