
import { FC, useEffect, useState } from "react";
import Img from "@/components/img";
import styles from "@/components/styles/buyCard.module.css"
import u from "@/util";
import useConfBase from "@/hooks/useConfBase";
import { Link } from "react-router-dom";
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
interface IBuycard {
    smallView?: boolean
}

const BuyCard: FC<IBuycard> = ({ smallView }) => {
    const [config, updateConfBase, getData] = useConfBase()
    // const { req } = useAxios("mall/goodList", "post");
    const [time, setTime] = useState<string>("24:00:00")
    const [data, setData] = useState<any[]>([])
    const { req } = useAxios("mall/goodList", "post");
    const [userInfo] = useUser();
    // const fetchProductDetails = useCallback( async () => {
    //     const res = await req({});
    //     if (res) {
    //         console.log(res?.data)
    //         if (Array.isArray(res?.data)) {
    //             console.log(res)
    //             setData(res?.data)
    //         } 
    //     }
    // }, [])

    // useEffect(() => {
    //     fetchProductDetails()
    // }, [fetchProductDetails])

      useEffect(() => {
        u.countdown(setTime)
      }, [])

      useEffect(() => {
        getData(2).then( async (res: any) => {
            if (smallView) {
                delete res[0]
            }

            if(res.length){
                setData(res)
            }else{
                const res = await req()

                if (res) {
                    setData(res.data)
                }
            }
        });
      }, [req])

    return (
        <div className={`mw1100 ${smallView && styles.smallView}`}>
            <div className="relative">
                <Img cls={`of_cover ${styles.top}`} height={180} src="/images/user-center/buy/buy_bg.png" />
                <div className={`abs ${styles.buy_main}`}>
                    <div className={styles.n_1}>终身VIP限时优惠</div>
                    <div className={styles.n_1}>{time}</div>
                    <div className={styles.n_2}>
                        限时优惠<span>200.00</span>元 <span className="text_line">原价5000.00</span>
                    </div>
                </div>
            </div>
            <div>
                {data.map((x, i) => {
                    return <Link key={i} className="d_none point" to={`/user/charge?id=${x.id}`}>
                        <div className={`${styles.vip_item} grid align_center`}>
                            <div className="grid align_center h100 gap5">
                                <div className="fl align_center gap10">
                                    <div className={`${styles.vip_item_title}`}>{x.title}</div>
                                    {x.activity === 1 && (
                                        <span className={`${styles.activitymark}`}>
                                            限时售卖
                                        </span>
                                    )}
                                    {x.is_hot === 1 && (
                                        <span className={`${styles.activitymark}`}>
                                           最优惠
                                       </span>
                                        // <Img width={50} height={16} src="/images/user-center/buy/youhui.png" />
                                    )}
                                </div>
                                <div className={`${styles.vip_old_price}`}>
                                    原价{x.old_cost}
                                </div>
                            </div>
                            <div>
                                <span className={styles.red_p}>{x.cost}</span>
                                <span>元</span>
                            </div>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    )
}
export default BuyCard;


// const vipItem = [
//     {
//         name: "VIP月卡",
//         originalPrice: 1000,
//         actual: 100
//     },
//     {
//         name: "VIP月卡",
//         originalPrice: 1000,
//         actual: 100
//     },
//     {
//         name: "VIP月卡",
//         originalPrice: 1000,
//         actual: 100
//     },
// ]
