import { FC, useMemo, useState } from "react"
import useAxios from "@/hooks/useAxios";
import { useCallback, useEffect } from "react";
import ImageList from "@/components/list";
import styles from "@/components/styles/layoutContent.module.css"
import u, { ELocalKey } from "@/util";
import useCategory from "@/hooks/useCategory";
import useConfBase from "@/hooks/useConfBase";
import NoticeModal from "@/components/modal/noticeModal";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import useFlipFlop from "@/hooks/useFlipFlop";

interface ILayoutContent {
    list: any[]
}

const LayoutContent: FC<ILayoutContent> = ({ list }) => {
    const { req } = useAxios("index/home", 'post',);
    const { req: reqNotice } = useAxios("notice/index", 'post',);
    const { req: reqStats } = useAxios("index/statistics", "post", true);
    const [data, setData] = useState<any>({})
    const [config] = useConfBase()
    const category = useCategory()
    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")

    const fetchVIPVideo = useCallback(async () => {
        const res = await req({ channel: "vip" })
        
        if (res?.data) {
            setData(res?.data)
        }

        const res2 = await reqNotice({site: '1'});
        console.log('notice---' , res2)
       
        if(res2 && res2?.data?.notice && new Date().getHours().toString() !== localStorage.getItem(ELocalKey._NOTICE)){
            localStorage.setItem(ELocalKey._NOTICE, new Date().getHours().toString());
            modalSubject$.next({
                id: EModalTypes.NOTICE,
                data: {content: res2?.data?.notice?.content ?? '', title: res2?.data?.notice?.title ?? ''},
                event: () => flipHander(0)
            })
        }
    }, [])

    useEffect(() => {
        fetchVIPVideo()
    }, [fetchVIPVideo])

    const handleMore = useCallback((row: any) => {
        hitStatis(row)
        if ((row?.channel === "vip" || row?.channel === "remen") && !u.isVip()) {
            return window.open("/user/buy")
        }
        if (row?.channel && row?._name) {
            const val = u.searchHomecategory(category, row?.channel, row?._name);
            return window.open(`/page/${row?.channel}/${val?.id}`)
        }

        
    }, [category,])

    const hitStatis = useCallback(async (x: any) => {
            const __val = u.getStatsParams(false, "home", x.channel + '-更多-' + x.realName, "") as any;
            console.log(__val , ' --- ', x )
            if (__val?.c && x?.count) {
                __val["c"] = __val["c"] + x?.count;
            }
            reqStats(__val)
    }, [])

    const _memoList = useMemo(() => {
        const _newdata = { ...data };
        delete _newdata["seo"]
        delete _newdata["notice"]
        _newdata["commen_list"] = { ..._newdata["commen_list"], "channel": "shipin" }
        return _newdata
    }, [data])

    // console.log(_memoList)

    return (
        <div>
            <NoticeModal/>
            <div className="mw1100">
                {_memoList && Object.values(_memoList).map((x: any, i) => {
                    return <div key={i}>
                        <div className={styles.main}>
                            <div className={styles.title}>{name[i]}</div>
                            <div onClick={() => handleMore({ ...x, _name: mapName[i], realName: name[i] })}>更多</div>
                        </div>
                        <ImageList is300={name[i] === '免费福利'} channel={x.channel} imgUrlkey="thumb" idkey="title" idValue="id" list={x.data} />
                    </div>
                })}
            </div>
        </div>
    )
}
export default LayoutContent;

const name = ["VIP视频", "推荐视频", "免费福利", "中文字幕", "亚洲无码", "美女主播"]
const mapName = ["VIP 专区", "短视频区", "精彩动漫", "中文字幕", "亚洲无码", "美女主播"]
