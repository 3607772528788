import useModal, { EModalTypes, IModal } from "@/hooks/useModal";
import { FC, useCallback, useEffect, useState } from "react";
import styles from "@/components/styles/modal.module.css"
import noticeModal from "@/components/styles/noticeModal.module.css"
import Button from "../button";
import u, { ELocalKey, EVIPtype } from "@/util";



const BuyTopicModal: FC<IModal> = () => {
    const [modalData, closeModal] = useModal();
    const [buy, setBuy] = useState<string>("");
    
    useEffect(() => {
      if(window.location.href.indexOf("login") >= 0 && localStorage.getItem(ELocalKey._BT)){
        setBuy(localStorage.getItem(ELocalKey._BT)+"")
    }
    }, [])

    const handleBuy = useCallback((price?: any) => {
        let id =window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1,window.location.pathname.length);
        if(localStorage.getItem(ELocalKey._BT)){
            id = localStorage.getItem(ELocalKey._BT)?.substring(0, localStorage.getItem(ELocalKey._BT)?.lastIndexOf('-')) ?? '6'
            localStorage.removeItem(ELocalKey._BT)
            return window.open(`/page/topic/${id}`)
        }
        
        if (!u.isLogin()) {
            localStorage.setItem(ELocalKey._BT,id + '-' + price);
            return window.location.href = "/login"
        }
        try {
            let i = price.toString();
            i = u.encrypt(i);
            u.open(`/user/chargetopic?id=`+id+`&i=`+i+`&ty=topic`)
        } catch(err) { 
            console.log(err)
        }
    }, [])

    return (
        modalData?.show && modalData?.id ===  EModalTypes.NOTICE && <div className={styles.common}>
            <div className={`${styles.body} notice_modal`}>
                <div onClick={() => {
                   closeModal()
                }} className={styles.close}>X</div>
                <div className={`${styles.content} ${noticeModal.content}`}>    
                    <div className={`${styles.mid_title} mt10`} > {modalData?.data.data.title} </div>
                    <div className={`${styles.content_inner_title} `} style={{paddingLeft: '10px', paddingRight: '10px'}} dangerouslySetInnerHTML={{ __html: modalData?.data.data.content }}>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BuyTopicModal;