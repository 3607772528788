import CategoryHead from "@/components/categoryHead"
import { FC, useCallback, useEffect, useState } from "react"
import styles from "./styles/bill.module.css"
import Footer from "@/components/footer"
import Back from "@/components/back"
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
import Empty from "@/components/empty"
import useRoleAuth from "@/hooks/useRoleAuth"
import u from "@/util"
import { modalSubject$, EModalTypes } from "@/hooks/useModal"
import copy from "copy-to-clipboard"
import useFlipFlop from "@/hooks/useFlipFlop"
import DownloadZip from "@/components/modal/downloadZip"

const UserBill: FC = () => {
    useRoleAuth()
    const [user] = useUser()
    const { req } = useAxios("user/getTopics", "post");
    const [list, setList] = useState<any[]>([])
    const fetchBillDetails = useCallback(async () => {
        if (!user?.token) return;
        const res = await req({
            token: user?.token
        });

        if (res) {
            let contentList = res?.data.topic_videos;
            try {
                setList(JSON.parse(contentList))
            } catch (e) {
                setList(contentList)
            }
        }
    }, [user])

    useEffect(() => {
        fetchBillDetails()
    }, [fetchBillDetails])

    const handleMore = useCallback((id: any) => {
        if (id) {
            return window.open(`/page/topic/${id}`)
        }
    }, [])

    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")
    const copyVal = useCallback((val: any) => {
        // if (!u.isMobile()) return;
        copy(val);
        modalSubject$.next({
            id: EModalTypes.DLZIP,
            data: {url: val },
            event: () => flipHander(0)
        })
    }, [])
    
    return (
        <CategoryHead>
            {<DownloadZip />}
            <div className="plr mh700 mt80">
                <div className="mw1100 mb10">
                    <Back>已购买主题</Back>
                    <div className={styles.up}>主题</div>
                    {
                        list && list.length > 0 && list.map((x, i) => {
                            return <div key={i}>
                                 <div className={styles.billitem}>
                                    <div>
                                        <div>{x.title}</div>
                                    </div>
                                    <div className={styles.rightbar}>
                                         <div className={styles.rightbtn} onClick={() => handleMore(x.id)}>在线观看</div>
                                         <div className={styles.rightbtn} onClick={() => copyVal(x.file)}>下载捆绑包</div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    {list.length === 0 && <Empty></Empty>}
                </div>
            </div>
            <Footer />
        </CategoryHead>
    )
}
export default UserBill