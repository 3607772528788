import React, { FC } from 'react'
import Img from '@/components/img';
import copy from 'copy-to-clipboard';
import u, { EVIPtype } from '@/util';
import { EModalTypes, modalSubject$ } from '@/hooks/useModal';
import style from "@/components/styles/fastSection.module.css"
import useUser from '@/hooks/useUser';

const FastSection: FC = () => {
    const [user] = useUser();
    return (
        <div className={style.fs}>
            {list(user).filter((x) => x.hide ? !x.hide() : true).map((x, i) => {
                return <div key={i} onClick={x.event}>
                    <Img src={x.img} width={25} height={25} />
                </div>
            })}
        </div>
    )   
}
export default FastSection;

const list = (r: any) => [
    {
        img: "/images/icon/arrow-up.png",
        hide: () => window.scrollY === 0,
        event: () => {
            return window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
    {
        img: "/images/help/share_4.png",
        event: () => {
            let text = `${window.location.href} 提示：请复制域名去手机浏览器中打开，请勿在微信和QQ中打开，因为包含成人内容。尽量使用宙斯浏览器，谷歌浏览器，火狐浏览器，或者苹果安卓系统自带浏览器访问～`
            copy(text);
            modalSubject$.next({
                id: EModalTypes.MASK,
            })
        },
    },
    {
        img: "/images/icon/customer.png",
        event: () => {
            let _url = u.formLogin(true);
            if (u.isLogin()) {
                return window.open(r?.custom_url || _url)
            }
            return window.open(_url)
        }
    },
]