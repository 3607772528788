import useAxios from "./useAxios";
import { useCallback, useEffect, useState } from "react";
import u, { ELocalKey, categoryConstant } from "@/util";
import { PROD_SITE } from "@/const/prod.const";

const useCategory = () => {
    const { req } = useAxios("category/base", 'post',);
    const [categoryList, setCategoryList] = useState<any[]>([])

    const fetchCategoryData = useCallback(async () => {
        const res = await req({ site: PROD_SITE });
        console.log('cat req' , res)
        // const res = await req();
        try {
            if (res && typeof res === "object" && res?.data?.menus) {
                const __v = Object.values(res?.data?.menus);
                setCategoryList(__v);
                localStorage.setItem(ELocalKey._CATER, u.encrypt(JSON.stringify(__v)))
            }
        } catch (e) {
        }
    }, [])

    useEffect(() => {
            if(!localStorage.getItem(ELocalKey._CATER)){ // first load only
                setCategoryList(Object.values(categoryConstant));
                localStorage.setItem(ELocalKey._CATER, u.encrypt(JSON.stringify(categoryConstant)))
            }

            if(!localStorage.getItem(ELocalKey._LOCK_CATER)){
                // 在hooks里一直被使用，需要锁
                localStorage.setItem(ELocalKey._LOCK_CATER, '1'); 
                fetchCategoryData()
            }else{
                const data = localStorage.getItem(ELocalKey._CATER);
                if(data){
                    const val =  JSON.parse(u.decrypt(data));
                    setCategoryList(val)
                }
            }
    }, [fetchCategoryData])
    return categoryList;
}
export default useCategory