import u, { ELocalKey } from "@/util";
import { FC, useCallback, useEffect, useState } from "react";
import Modal from "./modal";
import useConfBase from "@/hooks/useConfBase";
import useAxios from "@/hooks/useAxios";
import { changeConfigHandler } from "@/const/gl.const";
import { PROD_SITE } from "@/const/prod.const";
import { defaultListConst$ } from "@/const/defaultList.const";
import FeedbackModal from "./modal/feedbackModal";
import useUser from "@/hooks/useUser";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import PaymentModal, { EStatus, PaymentSubject$ } from "./modal/paymentModal";
import MooncakeModal from "./modal/MooncakeModal";
// import { R } from "@/req";
// import { getQuestionToken } from "@/apis/question";
// import StatusModal from "./modal/statusNormal";

const Global: FC = () => {
  const [localdata, updateConfBase] = useConfBase();
  const [userInfo, setUserInfo] = useUser()
  const { req: req3 } = useAxios("config/base", 'post');
  const [prevUrl, setPrevUrl] = useState<string>(window.location.pathname);

  useEffect(() => {
    if (prevUrl !== window.location.pathname) {
      setPrevUrl(window.location.pathname)
    }
  }, [prevUrl])

  useEffect(() => { 
    if (!u.isLogin()) return;
    const timer = setTimeout(u.logout, 10 * 60 * 60 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const fetchBaseDetails = useCallback(async () => {
    const res1 = await req3({ site: PROD_SITE })
    if (res1?.data) {
      let newConfBase = changeConfigHandler(res1?.data);
      defaultListConst$.next(newConfBase)
      newConfBase = { data: newConfBase };
      updateConfBase(newConfBase);
    }
  }, [])
  useEffect(() => {
    fetchBaseDetails();
  }, [fetchBaseDetails])

  useEffect(() => {
    const _value = window.location.pathname
    if (_value === "/") return;
    if (_value.indexOf("enter") >= 0) return;
    if (_value.indexOf("login") >= 0) return;
    if (_value.indexOf("register") >= 0) return;
    if (_value.indexOf("user") >= 0) return;

    const popupShown = localStorage.getItem('FEEDBACK') as any;
    const currentTime = new Date().getTime() as any;
    const isMatch = Math.random() > 0.75;
    if (isMatch && (!popupShown || (currentTime - popupShown > 3 * 24 * 60 * 60 * 1000))) {
      setTimeout(() => {
        modalSubject$.next({
          id: EModalTypes.FEEDBACK,
          innerTitle: "尊敬的用户，您对猫咪新版本的体验如何？有什么建议可以告知我们，也非常感谢您的参与让猫咪越来越好!",
          btn: true,
        })
        localStorage.setItem('FEEDBACK', currentTime);
      }, 60000);
    }

    const cacheShown = localStorage.getItem(ELocalKey._CACHE) as any;
    if (!cacheShown || (currentTime - cacheShown > 300000)) {
      localStorage.removeItem(ELocalKey._LOCK_FORMAT);
      localStorage.setItem(ELocalKey._FORMAT, "");
      localStorage.setItem(ELocalKey._MM, "");
      localStorage.setItem(ELocalKey._CACHE, currentTime);
    }
  }, [])

  useEffect(() => {
    if (window.location.href.indexOf("/user/charge") >= 0) return;
    if (u.isVip()) return;

    const currentTime = new Date().getTime() as any;
    const pSecond = localStorage.getItem(ELocalKey.__P_SECOND) as any;
    if (pSecond && currentTime - pSecond > 3 * 60 * 1000) {
      localStorage.removeItem(ELocalKey.__P_SECOND)
      return PaymentSubject$.next({
        status: EStatus.SECOND
      })
    }

    const pThird = localStorage.getItem(ELocalKey.__P_THIRD) as any;
    if (pThird && currentTime - pThird > 3 * 60 * 1000) {
      localStorage.removeItem(ELocalKey.__P_THIRD)
      return PaymentSubject$.next({
        status: EStatus.THIRD
      })
    }
  }, [prevUrl])

  useEffect(() => {
    let tokenval = window.location.search;
    if (tokenval.indexOf("?user=") >= 0) {
      tokenval = tokenval.replace("?user=", "");
      try {
        if (tokenval) {
          tokenval = u.decrypt(tokenval, 'djasjl')
          if (tokenval) {
            tokenval = JSON.parse(tokenval)
            setUserInfo({ data: tokenval })
          }
        }
  
      } catch { }
    }
  }, [])

  // const getQuesToken = useCallback( async () => {
  //   const res = await R.request(getQuestionToken({ email: "admin@admin.com", password: "password" }), false, false, 1);
  //   if (res?.token) {
  //     localStorage.setItem(ELocalKey.__QTKN, u.encrypt(res?.token))
  //   }
  // }, [])

  useEffect(() => {
    const qtkn = u.getLocalData(ELocalKey.__QTKN, true);
    if (!qtkn) {
      // getQuesToken()
      localStorage.setItem(ELocalKey.__QTKN, u.encrypt(u.getQtknToken()))
    }
  }, [])

  return (
    <div>
      <Modal />
      {/* <StatusModal /> */}
      <FeedbackModal />
      {/* <PaymentModal /> */}
      {/* <MooncakeModal /> */}
    </div>
  )
}

export default Global