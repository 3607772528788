import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from "./routes"
import { RouterProvider } from "react-router-dom";
import 'normalize.css';
import '@/global.css'
import 'swiper/css';
import Global from './components/global';
import Hls from 'hls.js';
import { Helmet } from "react-helmet";
// import u from './util';

const w = window as any
w.Hls = Hls;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

console.log('pricess.env', process.env)

root.render(
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{window?.location?.host}</title>
      {/* <script defer src={u.getScriptResources(1)} />
      <script async src={u.getScriptResources(2)} />
      <script defer src={u.getScriptResources(3)} />
      <script defer src={u.getScriptResources(4)} /> */}
    </Helmet>
    <Global />
    <RouterProvider router={router} />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
