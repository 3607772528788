import { FC, useMemo } from "react"
import { useParams } from "react-router-dom"
import Video from "./video"
import CategoryHead from "@/components/categoryHead";
import Picture from "./picture";
import Novel from "./novel";
import Sound from "./sound";
import Nvyou from "./nvyou"
import Tese from "./tese"
import Topic from "./topic"
import FloatLR from "@/components/floatLR";
import useScrollToPosition from "@/hooks/useScroll";

const Main: FC = () => {
    useScrollToPosition();
    const { channel } = useParams();

    const _memoCompIndex = useMemo(() => {
        if (!channel) return;
        if (!channel) {
            return 0
        }
        if (["vip", "shipin", "cili", "remen", "remen2", "remen3"].includes(channel)) {
            return 1
        }
        if (["tupian", "meinv"].includes(channel)) {
            return 2
        }
        if (["xiaoshuo"].includes(channel)) {
            return 3
        }
        if (["yousheng"].includes(channel)) {
            return 4
        }
        if (["nvyou"].includes(channel)) {
            return 5
        }
        if (["tese"].includes(channel)) {
            return 6
        }
        if (["topic"].includes(channel)) {
            return 7
        }
    }, [channel])

    switch(channel) {
        case 'tupian':
        case 'meinv':
            var _hmt:any = _hmt || [];
            (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?efb137c71c50a16b782dd4f1f9cbd29d";
                var s = document.getElementsByTagName("script")[0]; 
                console.log('ss', s)
                if(s.parentNode) {
                    s.parentNode.insertBefore(hm, s);
                }
            })();
            break;
        case 'cili':
            var _hmt:any = _hmt || [];
            (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?48d901a2f1882b1afaafc326dc8f22b6";
                var s = document.getElementsByTagName("script")[0]; 
                if(s.parentNode) {
                    s.parentNode.insertBefore(hm, s);
                }
            })();
            break;
        default:
            break;
    }

    return (
        <div>
            <CategoryHead showCategory flag={0}>
            {_memoCompIndex === 1 && <Video />}
            {_memoCompIndex == 2 && <Picture />}
            {_memoCompIndex == 3 && <Novel />}
            {_memoCompIndex == 4 && <Sound />}
            {_memoCompIndex == 5 && <Nvyou />}
            {_memoCompIndex == 6 && <Tese />}
            {_memoCompIndex == 7 && <Topic />}
            </CategoryHead>
            <FloatLR />
        </div>
    )
}
export default Main;
