import Back from "@/components/back";
import FloatLR from "@/components/floatLR";
import Footer from "@/components/footer";
import CategoryHead from "@/components/categoryHead";
import Video from "@/components/video";
import useAxios from "@/hooks/useAxios";
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom";
import u, { ELocalKey } from "@/util";
import Img from "@/components/img";
import TeseStyle from "@/pages/main/styles/tese.module.css"
import Button from "@/components/button";

const VideoId: FC = () => {
    const { id, channel } = useParams()
    const { req } = useAxios("shipin/detail", "post",)
    const { req: req2 } = useAxios("tese/play", "post",)
    const { req : req3 } = useAxios("tese/detail", "post");
    const { req : req4 } = useAxios("topic/play", "post");

    const [title, setTitle] = useState<string>("");
    const [data, setData] = useState<any>({});
    const [videourl, setVideoUrl] = useState<string>("");
    const [sourceurl, setSourceUrl] = useState<string>("");
    const [info, setInfo] = useState<any>(null)

    useEffect(() => {
        if (window.location.href.indexOf("tese") === -1) return;
        let val = localStorage.getItem(ELocalKey._TESE);
        try {
            val = JSON.parse(u.decrypt(val))
        } catch {
            return;
        }
        setInfo(val)
    }, [])

    const fetchVideodetails = useCallback(async () => {
        if (!id) return;
        let fn = channel === "tese" ? req2 : req 
        switch(channel){
            case "tese":
                fn = req2;
                break;
            case "shipin":
                fn = req;
                break;
            case "topic":
                fn = req4; // add on topic API
                break;
            default:
                fn = req;
                break;
        }
        const res = await fn({ id: id })
        if (res) {
            if (res?.data?.info?.channel === 'vip' || res?.data?.info?.channel === 'remen') {
                if (u.isLogin() && !u.isVip()) {
                    return window.location.href = "/user/buy"
                }
                if (!u.isLogin() || !u.isVip()) {
                    return window.location.href = "/login"
                }
                if (u.isVip()) {
                    if(channel === "tese"){
                        await fetchVidUrl({id: res?.data?.info?.provider_id, page: res?.data?.ad.site});
                    }
                    setTitle(res?.data?.info?.title)
                    setVideoUrl(res?.data?.source?.video_url)
                    setData(res?.data)
                }
            } else {
                if(channel === "tese"){
                    await fetchVidUrl({id: res?.data?.info?.provider_id, page: res?.data?.ad.site});
                }
                setTitle(res?.data?.info?.title)
                setVideoUrl(res?.data?.source?.video_url)
                setData(res?.data)
            }
        }
    }, [id, channel, req2, req])

    const fetchVidUrl = useCallback(async (details: any) => {
        const res = await req3(details)
        if (res) {
            setSourceUrl(res?.data?.host?.play_domain)
        }
    }, [req3])

    useLayoutEffect(() => {
        fetchVideodetails()
    }, [fetchVideodetails])

    const isTese = useMemo(() => {
        return window.location.href.indexOf("tese") >= 0
    }, [])

    const hasTag = useMemo(() => {
        return window.location.href.indexOf("tese") >= 0 || window.location.href.indexOf("topic") >= 0
    }, [])

    const goExternal = useCallback(() => {
        // const __val = u.getStatsParams(false, "tese");
        // await req2({ ...__val, category: "external", name: info?.url })
        return window.open(info?.url)
    }, [info])

    return (
        <div>
            <CategoryHead showCategory={true} flag={0}>
                {
                    isTese && <div className={`mt20 mw1100 ${TeseStyle.ts_container} ${TeseStyle.main}`}>
                        <div onClick={goExternal}>
                            <Img src={info?.logo} cls={`${TeseStyle.ts_logo}`} />
                        </div>

                        <div>
                            <div className={TeseStyle.ts_title}>{info?.nickname}</div>
                            <div className={TeseStyle.desc}>
                                {info?.desc}
                            </div>
                        </div>

                        <div className={TeseStyle.left_cover}>
                            <Button event={goExternal} cls={`btn_sty2`}>
                                进入官网
                            </Button>
                        </div>
                    </div>
                }

                {
                    isTese && <div className={`${TeseStyle.ts_container} ${TeseStyle.main1} ${TeseStyle.ts_vid_container}`}>
                        <div onClick={goExternal}>
                            <Img src={info?.logo} cls={`${TeseStyle.ts_logo}`} />
                        </div>
                        <div className={TeseStyle.ts_title} onClick={goExternal}>
                            {info?.nickname}
                        </div>
                        <div>
                            <Button event={goExternal} cls={`btn_sty2 ${TeseStyle.ts_btn}`}>
                                进入官网
                            </Button>
                        </div>
                    </div>
                }

                <div className="grid gap10">
                    <Back cls={isTese ? 'mt20' : ''}>视频详情</Back>
                    <div className="mw1100 m0auto">
                        <Video hideBuyCard={isTese} hideMiddle={isTese} data={{ ...data, _info: info }} title={title} vidurl={videourl} sourceurl={sourceurl} tag={hasTag ?
                            <div className="fl point gap10 align_center" onClick={goExternal}>
                                <Img cls={TeseStyle.ts_de_img} src={info?.logo} width={15} height={15} />
                                <div className={TeseStyle.ts_detail}>
                                    {info?.nickname}
                                </div>
                            </div> : ''
                        } />
                    </div>
                    <Footer />
                </div>
            </CategoryHead>
            <FloatLR />
        </div>
    )
}
export default VideoId