import axios, { InternalAxiosRequestConfig } from "axios";
import u, { ELocalKey } from "@/util"
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"
import { PROD_SITE } from "@/const/prod.const";
import useConfBase from "@/hooks/useConfBase";


let cancelTokenSource = null as any;

export function cancelAllRequests() {
    if (cancelTokenSource) {
        cancelTokenSource = axios.CancelToken.source();
        cancelTokenSource.cancel();
    }
}

axios.interceptors.request.use((config: InternalAxiosRequestConfig<any> & { __data?: any, __endpoint?: any }) => {
    const val = u.getLocalData(ELocalKey._BASE)?.config?.json_host || 'https://ut.yishengguangmei.xyz/';
    if (!val) return config;
    let _url = `/${config?.__endpoint}`;
    if (_url && _url.indexOf("index/home") >= 0) {
        config.url = `${val}data${_url}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("config/base") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("notice/index") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("enter/base") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("appdown/base") >= 0) {
        config.url = `${val}data${_url}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("appdown/detail") >= 0) {
        config.url = `${val}data${_url}-${config.__data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("category/base") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("list/base") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}-${___data.name}-${___data.page}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("tupian/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("shipin/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("cili/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("meinv/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("xiaoshuo/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("yousheng/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("news/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("chapter/base") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}-${___data.id}-${___data.page}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("index/base") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("shipin/nvyou") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}-${___data.page}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("tese/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}-${___data.page}.json`;
        config.method = "get"
    }
    if (_url && _url.indexOf("tese/play") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.json`;
        config.method = "get"
    }
    return config;
},
    (error) => {
        return Promise.reject(error);
    })

interface IPayload {
    url: string;
    method: string;
    data?: any;
    baseUrlType?: number;
}

const controllerRef = new AbortController()
class Request {
    noEncrypt = false;
    baseUrl = 'http://172.247.9.210:8901/api';
    // baseUrl = 'https://newapizxzxzx.zhenzifang8.com/api';//"http://172.247.9.210:8901/api";
    isProd = process.env.NODE_ENV === "production";

    getBaseUrl = (encrypt?: boolean, baseType?: number) => {
        if (this.isProd) {
            this.baseUrl = u.getLocalData(ELocalKey._BASE)?.api_url || "https://mmnew.tlxxw.cc/api"
        }
        if (!encrypt) {
            this.baseUrl = 'https://api2.2c5r6.com/'
        }
        if (baseType === 1) {
            this.baseUrl = this.isProd ? "https://apicerahk.srbs35.com/api/v1" : "http://23.224.129.130:9080/api/v1"
        }
        return this.baseUrl
    }

    formParams = (data: any, encrypt?: boolean) => {
        let passPayload = {} as any
        passPayload["system"] = u.isMobile() ? 2 : 1;
        passPayload["timestamp"] = new Date().getTime();
        passPayload["device"] = u.isMobile() ? 'mobile' : 'pc'
        passPayload = { ...passPayload, ...data?.data }

        if (encrypt) {
            passPayload["encode_sign"] = u.base64Sign(passPayload);
            passPayload = { "post-data": u.encrypt(JSON.stringify(passPayload)) };
        } else {
            // delete passPayload["system"];
            // delete passPayload["timestamp"];
        }
        return {
            submitData: passPayload,
            original: data
        }
    }

    /**
     * @param raw 
     * @param baseType 1 => question api
     */
    request = async (payload: IPayload, encrypt?: boolean, raw?: boolean, baseType?: number, cb?: any) => {
        const { url, method } = payload;
        const [config] = useConfBase()
        let __baseUrl = this.getBaseUrl(encrypt, baseType);

        if(__baseUrl.includes('api2')){
            __baseUrl = config.tongji_domain
        }
        
        const { submitData, original } = this.formParams(payload, encrypt);
        const submitParams = {
            // timeout: 2000,
            data: submitData,
            method,
            signal: controllerRef.signal,
            url: `${__baseUrl}/${url}`,
            __data: original,
            __endpoint: url,
        } as any;

        if (submitData?.token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${submitData?.token}` 
        }

        try {
            delete submitData["token"]
            const response = await axios.request(submitParams);
            if (raw) {
                response.data._raw = { status: response.status }
            }
            if (encrypt) {
                let responseData = u.decrypt(response.data.data, response.data.suffix);
                responseData = JSON.parse(responseData)
                response.data.data = responseData;
            }
            cb && cb()
            return response.data;
        } catch (e) {
            if (url == "config/base") {
                modalSubject$.next({
                    id: EModalTypes.NORMAL,
                    innerTitle: "链接失败，请稍后再试！",
                    btn: true,
                })
            }
            cb && cb()
        }
    }

    then = () => {

    }
}
export const R = new Request()