import Button from "@/components/button";
import Img from "@/components/img";
import Input from "@/components/input";
import { FC, useCallback, useState } from "react";
import styles from "./styles/register.module.css"
import { Link } from "react-router-dom";
import LoginWrapper from "@/components/loginWrapper";
import useAxios from "@/hooks/useAxios";
// import Modal from "@/components/modal";
import useValidation from "@/hooks/useValidation";
import { vID } from "@/const/validate.const";
// import { IUserRegister } from "@/const/api.const";
import useFlipFlop from "@/hooks/useFlipFlop";
import useUpdateForm from "@/hooks/useUpdateForm";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"
import ChargeModal from "@/components/modal/chargeModal";
import u from "@/util";
import useFocus from "@/hooks/useFocus";
import useUser from "@/hooks/useUser";

const Register: FC = () => {
    const [formData, setFormData] = useState<any>({
        username: "",
        password: "",
        password2: ""
    })

    const [flipText, flipHander] = useFlipFlop("注册", "注册中...")
    const { req } = useAxios("user/register", 'post');
    const updateForm = useUpdateForm(setFormData);
    const [userInfo, setUserInfo] = useUser()

    const [isValidSubmit, msgStatus] = useValidation(formData, {
        username: [vID.USERNAME_EMPTY, vID.MIN_TEXT_6_12],
        password: [vID.PWD_EMPTY, vID.MIN_TEXT_6_20],
        password2: [vID.PWD2_EMPTY, vID.PWD_PWD2_NOT_MATCH],
    }, "password")

    const register = useCallback(async () => {
        if (!isValidSubmit) return;
        flipHander(1);
        const res = await req(formData);

        if (res.code == 0) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: "你的会员账号已创建",
                btn: true,
                event: () => {
                    flipHander(0)
                    setUserInfo(res?.data)
                    u.open("/user/buy")
                }
            })
        } else {
            modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res?.msg,
                btn: true,
                event: () => flipHander(0)
            })
        }
    }, [isValidSubmit, msgStatus, flipHander, formData])

    useFocus(register)
    return (
        <>
                <div className={`grid ${styles.register} bgfa`}>
                    <div className={`grid ${styles.form} mauto`}>
                        <div className={styles.header}>建立你的账户</div>
                        <div className={`grid ${styles.inner2}`}>
                            <div className={`grid ${styles.inner3}`}>
                                <div>
                                    <Input errMsg={msgStatus[0]} value={formData.username} onChange={(e) => updateForm({ ...formData, "username": e })} prefix={<Img src="/images/icon/icon_user.png" width={20} height={20} />} line placeholder="输入用户名" />
                                </div>
                                <div>
                                    <Input errMsg={msgStatus[1]} value={formData.password} onChange={(e) => updateForm({ ...formData, "password": e })} prefix={<Img src="/images/icon/icon_password.png" width={20} height={20} />} line type="password" placeholder="密码" />
                                </div>
                                <div>
                                    <Input errMsg={msgStatus[2]} value={formData.password2} onChange={(e) => updateForm({ ...formData, "password2": e })} prefix={<Img src="/images/icon/icon_password2.png" width={20} height={20} />} type="password" line placeholder="确认密码" />
                                </div>
                                <div className={styles.blue}>*您可在注册之后再选择是否绑定手机号码</div>
                            </div>
                            <div>
                                <Button event={register} cls={`btn_sty1 ${u.themeBtnCls()}`}>
                                    <Link className="a_w" to={u.formRegister(true)}>{flipText}</Link>
                                </Button>
                                <div className={styles.btm}>
                                    已有账号？  <Link className={`${styles.click_register} fw500 d_none`} to={u.formLogin(true)}>点击登录</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginWrapper />
                </div>
            {/* <Modal cls="common_modal" /> */}
            <ChargeModal />
        </>
    )
}

export default Register;