import Back from "@/components/back"
import SectionBar from "@/components/sectionBar"
import SelectBar from "@/components/selectBar"
import CategoryHead from "@/components/categoryHead"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import Text from "@/components/text"
import styles from "./styles/charge.module.css"
import Button from "@/components/button"
import Footer from "@/components/footer"
import useAxios from "@/hooks/useAxios"
// import Modal from "@/components/modal"
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"
import useUser from "@/hooks/useUser"
import useRoleAuth from "@/hooks/useRoleAuth"
import u from "@/util"
import useConfBase from "@/hooks/useConfBase"
import { EStatus, PaymentSubject$ } from "@/components/modal/paymentModal"
import { PROD_SITE } from "@/const/prod.const";

const Charge: FC = () => {
    useRoleAuth()
    const [config, updateConfBase, getData] = useConfBase();
    const [user] = useUser()
    const { req } = useAxios("mall/payList", "post");
    const { req: req2 } = useAxios("mall/recharge", "post");
    const { req: req3 } = useAxios("mall/payListPrice", "post");
    // const { req: req4 } = useAxios("mall/rechargeTopic", "post");
    const [formData, setFormData] = useState({
        payType: 0,
        payTunnel: 0,
    })
    const [list, setList] = useState<any[]>([])
    const [goodsList, setGoodsList] = useState<any[]>([])

    const getQuery = useCallback((str: string) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        return params.get(str);
    }, [])
    const [promotion, setPromotion] = useState<any>(null)
    const [goods, setGoods] = useState<any>(null)

    const fetchPaymentList = useCallback( async () => {
        const _v = getQuery("id");
        const _price = getQuery("i");
        let _type = getQuery("ty");

        if (!_v) return;
        if (!user?.token) return;

        let fn = (_type && _type === "topic") ? req3 : req 
        let fnParam = (_type && _type === "topic") ? 
        {
            token: user?.token,
            cost: u.decrypt(_price)
        } : {
            token: user?.token,
            id: _v
        }

        if(_price){
            setGoods(u.decrypt(_price));
        }
        const res = await fn(fnParam)
        if (res) {
            console.log(res, fnParam)
            if (Array.isArray(res?.data)) {
                const newlist = res?.data.map((x: any) => {
                    const findType = type.find((t) => t.name === x.name);
                    if (findType) {
                        return { ...x, img: findType.img }
                    }
                    return x
                })
                setList(newlist);
                setFormData({
                    payType: newlist[0]?.id,
                    payTunnel: newlist[0]?.list[0]?.id,
                })
            }
        }
    }, [user, getQuery])

    useEffect(() => {
        fetchPaymentList()
    }, [fetchPaymentList])

    const newPaymentList = useMemo(() => {
        return list.find((x) => x.id === formData.payType)?.list || []
    }, [formData, list])

    const _memoTValue = useMemo(() => {
        let _val = getQuery("t");
        if (!_val) return ""
        _val = u.randValueToChar(_val[0])
       return _val;
    }, [])

    const goPay = useCallback( async () => {
         // 当成用户购买成功 通知payment不展示优惠的弹窗
        PaymentSubject$.next({ status: EStatus.BOUGHT })
        modalSubject$.next({
            id: EModalTypes.MASK,
            child: "正在处理中"
        })
        const _v = getQuery("id");
        const _type = getQuery("ty");

        let msgContent = _type === "topic" ? "请在新页面中完成支付，支付完成后即可观看购买主题影片。" :"请在新页面中完成支付，支付完成后需要重新登录，即可获得猫咪VIP。";
        let fn = _type === "topic" ? req2 : req2 
        let fnParam = _type === "topic" ? 
        {
            id: _v,
            pay_type: formData.payType,
            plat_id: formData.payTunnel,
            promotion: _memoTValue === "2" ? 1 : _memoTValue === "3" ? 2 : "",
            token: user?.token,
            site: PROD_SITE,
            mode: 'topic'
        } : {
            id: _v,
            pay_type: formData.payType,
            plat_id: formData.payTunnel,
            promotion: _memoTValue === "2" ? 1 : _memoTValue === "3" ? 2 : "",
            token: user?.token,
            site: PROD_SITE,
            mode: 'normal'
        }

        console.log('fnParam', fnParam)


        const res = await fn(fnParam)
        if (res?.code === 0) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: msgContent,
                btn: true,
                btnName: "立即跳转",
                event: () => {
                    return u.open(res?.data?.pay_url)
                }
            })
        } else {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res?.msg,
                btn: true,
            })
        }
    }, [formData, user, _memoTValue])

    useEffect(() => {
        getData(2).then((res: any) => {
            setGoodsList(res)
        });
    }, [config])

    const memoAmount = useMemo(() => {
        const _val = _memoTValue;
        const original = goodsList.find((x) => x.id == getQuery("id"))?.cost
        if(getQuery("ty") && getQuery("ty") === "topic" && goods){
            return goods + ".00";
        }
        if (_val) {
            return _val === "2" ? config?.promo_1 : _val === "3" ? config?.promo_2 : original
        }
        return original
    }, [goodsList, config, _memoTValue])

    useEffect(() => {
       const val = _memoTValue;
       setPromotion(val)
    }, [_memoTValue])

    return (
        <div>
            <CategoryHead>
                <div className="con_lay mt80">
                    <Back onClick={() => {
                        if (u.isPermenantVip()) return;
                         PaymentSubject$.next({
                            status: EStatus.NOBUY
                        })
                    }}>支付方式</Back>
                    <div className="grid gap10 mw1100">
                        <div className="grid gap10">
                            <SectionBar title="支付金额" rightText={memoAmount}>
                            </SectionBar>
                            <SectionBar title="支付方式">
                                <SelectBar idKey="id" onChange={(_, row) => setFormData({ ...formData, payType: row?.id, payTunnel: row?.list[0]?.id })} list={list} value={formData.payType} />
                            </SectionBar>
                            <SectionBar title="渠道选择">
                                <SelectBar idKey="id" height={cp} onChange={(_, row) => setFormData({ ...formData, payTunnel: row?.id })} list={newPaymentList} value={formData.payTunnel} />
                            </SectionBar>

                            <div className="grid gap10">
                                    <div className={styles.p_content}>
                                        <Text type="red">注意：付款成功之后请退出账户，重新登录即可获得猫咪VIP。</Text>
                                    </div>
                                    <div className={styles.p_content}>
                                        <Text type="red">部分浏览器拦截支付窗口，若点击确认后无反应，请到设置关闭浏览器新窗口拦截</Text>
                                    </div>
                                    <div className={`${styles.p_content} grid gap10`}>
                                        <Text>虚拟币购买安全，便捷。用户可以通过数字货币交易平台来购买USDT(虚拟货币)，在猫咪进行购买VIP。</Text>
                                        <Text type="bold">购买USDT数字货币详细教程</Text>
                                        <Text>USDT购买的优势</Text>
                                        <Text>泰达币（Tether）也被称为USDT，其价值等同于美元。1 USDT≈1 美元，该特点使USDT成为全球加密数字货币中规模最大、流通性最高的稳定币，具有良好的保值性，也是网站所使用的主要币种。</Text>
                                        <Text>USDT高度加密</Text>
                                        <Text>与银行卡等传统交易方式相比，用户不需要给出自己的姓名或卡号即可完成虚拟币交易，避免敏感信息泄露。</Text>
                                        <Text>USDT去中心化</Text>
                                        <Text>不由央行或当局发行，不受银行监管，用户可随心所欲地使用存放在自己数字钱包里的资金</Text>
                                    </div>
                                </div>
                        </div>
                        <Button event={goPay} cls="btn_sty5 mauto">确认支付</Button>
                        <div className="text_center">
                            猫咪温馨提示：如对支付有任何疑问，请<span  onClick={() => window.open(user?.custom_url)} className="kf">联系客服</span>，我们会尽快为你解决
                        </div>
                    </div>
                </div>
                <Footer />

            </CategoryHead>
            {/* <Modal /> */}
        </div>
    )
}
export default Charge;

const cp = { height: "25px", display: "flex", alignItems: "center", justifyContent: "center" }

const type =  [
    {
        name: "支付宝",
        img: "/images/zhifubao.png"
    },
    {
        name: "微信",
        img: "/images/weixin.png"
    },
    {
        name: "数字货币",
        img: "/images/ustd.png"
    },
]